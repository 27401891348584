<template>
  <li class="inner-list__item">
    <div class="inner-list__item-title">
      {{ $t(`dashboard.patientDiscomfortQuestion.${skinItem.name}`) }}
    </div>
    <div class="inner-list__item-status flex">
      <div class="flex-column">
        <div class="inner-list__mark skin-mark">
          <div
            v-for="score in itemMaxScore"
            :key="score"
            class="skin-mark__item"
            :class="{ 'skin-mark__item--selected': score === itemValue }"
            :style="[pointColorStyle, getPointBackgroundColor(score)]"
          ></div>
        </div>
        <span class="inner-list__item-text" :style="colorStyle">
          {{ itemName }}
        </span>
      </div>
      <div class="inner-list__item-descr">
        {{ bodyParts }}
      </div>
    </div>
  </li>
</template>

<script>
import { get, isEmpty } from 'lodash';

const BLACK_COLOR = '#000000';

export default {
  name: 'SkinItemDysfunction',
  props: {
    skinItem: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: BLACK_COLOR
    }
  },
  computed: {
    hasSeverity() {
      return !!this.skinItem.severity;
    },
    hasLikelihood() {
      return !!this.skinItem.likelihood;
    },
    hasPresent() {
      return this.skinItem.present !== null;
    },
    presentValue() {
      return Number(this.skinItem.present);
    },
    itemObject() {
      const { severity, likelihood, present } = this.skinItem;

      if (this.hasSeverity) {
        return severity;
      }

      if (this.hasLikelihood) {
        return likelihood;
      }

      return present;
    },
    itemMaxScore() {
      return get(this.itemObject, 'choicesCount', 2);
    },
    itemValue() {
      return this.hasPresent ? this.presentValue + 1 : get(this.itemObject, 'value');
    },
    itemName() {
      let name;

      if (this.hasPresent) {
        name = this.presentValue ? 'yes' : 'no';
      } else {
        name = get(this.itemObject, 'name', 'no');
      }

      return name ? this.$t(`dashboard.diagnosticDetails.${name}`) : '';
    },
    hasBodyParts() {
      return !isEmpty(this.skinItem.bodyPart);
    },
    bodyPartsWithTranslation() {
      return this.skinItem.bodyPart
        .map(bodyPart => this.$i18n.t(`dashboard.diagnosticDetails.${bodyPart}`))
        .join(', ');
    },
    bodyParts() {
      return this.hasBodyParts ? this.bodyPartsWithTranslation : '';
    },
    colorStyle() {
      return {
        color: this.color
      };
    },
    pointColorStyle() {
      return {
        'border-color': this.color
      };
    }
  },
  methods: {
    getPointBackgroundColor(score) {
      const pointBackgroundColor = {};

      if (this.itemValue === score) {
        pointBackgroundColor.backgroundColor = this.color;
      }

      return pointBackgroundColor;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.inner-list {
  list-style: none;

  &__item {
    display: flex;
    margin-bottom: 15px;
    padding-right: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-title {
    width: 100%;
    max-width: 130px;
    margin-right: 17px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: right;
    color: $text-color;
    word-break: break-word;
  }
  &__item-status {
    margin-right: 17px;
  }
  &__item-text {
    font-size: 13px;
    line-height: 15px;
  }
  &__item-descr {
    font-size: 11px;
    line-height: 15px;
    color: $grey-color;
  }
  &__mark {
    margin-bottom: 4px;
  }
}

.skin-mark {
  display: flex;
  align-items: center;
  width: 110px;

  &__item {
    width: 12px;
    height: 12px;
    border: 1px solid;
    border-radius: 50%;
    margin-right: 5px;

    &--selected {
      background: black;
    }
  }
}

@media (max-width: 767px) {
  .inner-list {
    &__item-title {
      width: 65%;
      max-width: 108px;
      margin-right: 10px;
      margin-left: auto;
    }

    &__item-status {
      width: 100%;
      max-width: 220px;
      margin-right: auto;
    }
  }
}
</style>
