<template>
  <div class="full-width">
    <div class="full-width" @click="isShown = !isShown">
      <slot name="toggle" :is-shown="isShown"></slot>
    </div>

    <transition name="slide">
      <div v-show="isShown" class="full-width">
        <slot name="slide-body" :is-shown="isShown"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SlideDown',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isShown: false
    };
  },
  watch: {
    show(value) {
      this.isShown = value;
    }
  },
  mounted() {
    this.isShown = this.show;
  }
};
</script>

<style scoped>
.slide-enter-active,
.slide-leave-active {
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
}

.slide-enter-to,
.slide-leave {
  max-height: 200px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
