<template>
  <header class="mobile-header flex--align-center bold">
    <slot name="back-button">
      <div
        class="mobile-header__icon icon-arrow-long-left custom-icon"
        @click="returnToPreviousPage"
      ></div>
    </slot>
    <slot name="header-title"></slot>
    <slot name="header-dropdown"></slot>
  </header>
</template>

<script>
export default {
  name: 'MobileHeader',
  props: {
    shouldRedirect: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    returnToPreviousPage() {
      this.$emit('return-back');

      if (this.shouldRedirect) {
        this.$router.go(-1);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/css/common/icons.css';
@import '../../../../assets/scss/helpers/colors';

@media (min-width: 768px) {
  .mobile-header {
    display: none;
  }
}

@media (max-width: 767px) {
  .mobile-header {
    height: 46px;
    padding: 0 15px;
    margin-bottom: 10px;
    background-color: $dim-white;
    font-size: 13px;
    line-height: 16px;
    position: fixed;
    z-index: 999;

    &__icon {
      margin-right: 10px;
    }
  }
}
</style>
