<template>
  <div class="flex-column">
    <span v-if="hasTitle" class="dysfunctions-list__title">
      {{ $t('dashboard.diagnostic.dysfunctions') }}
    </span>
    <ul class="dysfunctions-list flex-column">
      <dysfunction
        v-for="dysfunction in skinDysfunctions"
        :key="dysfunction.name"
        :skin-dysfunction="dysfunction"
        :is-questionnaire-diagnostic="isQuestionnaireDiagnostic"
      ></dysfunction>
    </ul>
  </div>
</template>

<script>
import { pick, omit } from 'lodash';

import Dysfunction from '@/modules/dashboard/components/diagnostic-tab/Dysfunction';

import {
  SELECT_DATA_FIELDS,
  COLORS_TO_ACTIVES_COMPATIBILITY,
  ACTIVES
} from '@/modules/dashboard/api/constants';

const QUESTIONNAIRE_DIAGNOSTIC_FIELDS_TO_OMIT = ['currentPhototype', 'normalPhototype', 'skinType'];

const PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT = [
  ...SELECT_DATA_FIELDS,
  'ageGroup',
  'ethnicity',
  'skinReactsToProducts'
];

const SECTIONS_DYSFUNCTIONS_FIELDS = {
  [ACTIVES.SKIN_APPEARANCE]: ['hasAcneScars', 'wrinklesDepth'],
  [ACTIVES.SKIN_REDNESS]: ['hasRosacea1', 'hasCouperose'],
  [ACTIVES.SKIN_DRYNESS]: ['dryness', 'dehydration'],
  [ACTIVES.PIMPLES]: ['hasAcneVulgaris', 'hasAcneRosacea', 'hasAcneNodular'],
  [ACTIVES.SKIN_PIGMENTATION]: ['comesFromPIH', 'comesFromAgeSpotsMelasma']
};

export default {
  name: 'SkinDysfunctionsList',
  components: { Dysfunction },
  props: {
    diagnostic: {
      type: Object,
      default: () => ({})
    },
    isQuestionnaireDiagnostic: {
      type: Boolean,
      default: false
    },
    hasTitle: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    fieldsToOmit() {
      return this.isQuestionnaireDiagnostic
        ? QUESTIONNAIRE_DIAGNOSTIC_FIELDS_TO_OMIT
        : PHOTO_DIAGNOSTIC_FIELDS_TO_OMIT;
    },
    skinDysfunctions() {
      const { questionnaireSkinCharacteristicsDto, photoSkinCharacteristicsDto } = this.diagnostic;

      const skinCharacteristics = this.isQuestionnaireDiagnostic
        ? questionnaireSkinCharacteristicsDto
        : photoSkinCharacteristicsDto;

      return this.getSkinDysfunctions(skinCharacteristics);
    }
  },
  methods: {
    getSkinDysfunctions(skinCharacteristics) {
      const dysfunctionsFields = omit(skinCharacteristics, this.fieldsToOmit);

      if (this.isQuestionnaireDiagnostic) {
        return dysfunctionsFields;
      }

      return Object.entries(dysfunctionsFields).reduce(
        (skinDysfunctions, [dysfunctionColor, score]) => {
          const dysfunctionSection = COLORS_TO_ACTIVES_COMPATIBILITY[dysfunctionColor];

          if (!dysfunctionSection) {
            return skinDysfunctions;
          }

          const sectionDysfunctionsFieldsToOmit = SECTIONS_DYSFUNCTIONS_FIELDS[dysfunctionSection];

          const sectionDysfunctionsFields = pick(
            skinCharacteristics,
            sectionDysfunctionsFieldsToOmit
          );

          const items = Object.entries(sectionDysfunctionsFields).map(([key, value]) => ({
            name: key,
            value
          }));

          return {
            ...skinDysfunctions,
            [dysfunctionSection]: {
              name: dysfunctionSection,
              score,
              items
            }
          };
        },
        {}
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';

.dysfunctions-list {
  padding: 0;
  margin-bottom: 15px;

  &__title {
    padding-left: 163px;
    font-size: 13px;
    line-height: 19px;
    color: $grey-color;
  }
}

@media (max-width: 767px) {
  .dysfunctions-list {
    &__title {
      margin: 0 auto;
      padding: 0 46px 0 0;
    }
  }
}

@media (max-width: 369px) {
  .dysfunctions-list {
    &__title {
      margin-left: 123px;
    }
  }
}
</style>
