<template>
  <modal
    name="photo-zoom-modal"
    adaptive
    scrollable
    :width="modalWidth"
    :height="modalHeight"
    class="mobile-fullscreen-modal"
    :classes="['modal-window']"
    @before-open="beforeOpen"
  >
    <div class="photo-zoom flex--center--center">
      <button
        class="photo-zoom__close-button custom-icon icon-close onlyXS"
        @click="closeModal"
      ></button>
      <img
        :src="photoUrl"
        alt="zoomed photo"
        class="photo-zoom__image"
        @click="closeModalOnDesktop"
      />
    </div>
  </modal>
</template>

<script>
import { isMobileDevice } from '@/modules/dashboard/utils';

export default {
  name: 'PhotoZoomModal',
  data() {
    return {
      photoUrl: '',
      modalWidth: '60%',
      modalHeight: 'auto'
    };
  },
  created() {
    if (isMobileDevice()) {
      this.modalWidth = '100%';
      this.modalHeight = '100%';
    }
  },
  methods: {
    beforeOpen({ params: { photoUrl } }) {
      this.photoUrl = photoUrl;
    },
    closeModalOnDesktop() {
      if (!isMobileDevice()) {
        this.closeModal();
      }
    },
    closeModal() {
      this.$modal.hide('photo-zoom-modal');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons.css';

.photo-zoom {
  width: 100%;
  height: 100%;

  &__close-button {
    position: absolute;
    top: 20px;
    left: 50%;

    transform: translateX(-50%);

    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid $light-blue;
    outline: none;

    background-color: $white;
    background-size: 12px 12px;
  }

  &__image {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
  .photo-zoom {
    width: 100vw;
    height: 100vh;
    padding-bottom: 70px;

    background-color: $white;
  }
}
</style>
